<template>
  <CRow>
    <CCol>
      <div v-if="showCardboardForm">
      <CCard style="width:50%;margin:0 auto;box-shadow: 0 0 3px #d7d7d7;">
        <CCardHeader>
          <strong>{{ cardHeaderTitle }}</strong>
        </CCardHeader>
        <CCardBody>
          <vue-confirm-dialog></vue-confirm-dialog>
          <CForm>
            <CRow>
              <CCol sm="9" style="margin:0 auto;">
                <CInput
                    id="date"
                    :label="shopIdLogin === wareHouse ? '出荷日' : '返品日'"
                    horizontal
                    v-model="cardboard.shipped_at"
                    type="date"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="9" style="margin:0 auto;">
                <CSelect
                    id="shop"
                    :label="shopIdLogin === wareHouse ? '出荷先' : '返品先'"
                    horizontal
                    v-model="cardboard.shop_id"
                    :options="shopOptions"
                    @change="selectShopChange"
                    :placeholder="shopIdLogin === wareHouse ? '出荷先' : '返品先'"
                    :invalid-feedback="errorCardboard && errorCardboard.shop_id ? errorCardboard.shop_id : ''"
                    :is-valid="errorCardboard && errorCardboard.shop_id ? false : null"
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="9" style="margin:0 auto;">
                <CSelect
                    id="user"
                    :label="shopIdLogin === wareHouse ? '出荷担当者' : '返品担当者'"
                    horizontal
                    v-model="cardboard.pick_user_id"
                    :options="userOptions"
                    @change="selectUserChange"
                    :placeholder="shopIdLogin === wareHouse ? '出荷担当者' : '返品担当者'"
                    :invalid-feedback="errorCardboard && errorCardboard.pick_user_id ? errorCardboard.pick_user_id : ''"
                    :is-valid="errorCardboard && errorCardboard.pick_user_id ? false : null"
                />
              </CCol>
            </CRow>
          </CForm>
        </CCardBody>
        <CCardFooter class="text-sm-center">
          <CButton @click="confirmRenderItemMaster" color="primary">
            次へ
          </CButton>
        </CCardFooter>
      </CCard>
      </div>
      <div v-if="showItemForm">
        <CCard>
          <CCardHeader>
            <strong>段ボールに商品追加</strong>
          </CCardHeader>
          <CCardBody>
            <vue-confirm-dialog></vue-confirm-dialog>
            <SelectItemCardboard @addItemForCardboard="addItem" @goBack="goBackCardboard"></SelectItemCardboard>
          </CCardBody>
        </CCard>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import ResourceApi from "@/api/resourceApi";
import Vue from "vue";
import {SCREEN_KEY} from "@/utils/constance";
import SelectItemCardboard from "@/views/cardboards/SelectItemCardboard";
import userApi from "@/api/userApi";

const apiUser = new ResourceApi('users');
const apiShop = new ResourceApi('shops');
const apiCardboard = new ResourceApi('cardboard');
import {WAREHOUSUE} from "@/utils/constance";

export default {
  name: 'CreateCardboard',
  components: {SelectItemCardboard},
  data () {
    return {
      errors: {},
      cardboard: {
        shipped_at: new Date().toISOString().slice(0,10),
        shop_id: '',
        pick_user_id: '',
      },
      shopOptions: [],
      userOptions: [],
      showItemForm: false,
      showCardboardForm: true,
      errorCardboard: {
        shop_id: '',
        pick_user_id: ''
      },
      wareHouse: WAREHOUSUE,
      shopIdLogin: this.$store.getters.user.shop_id,
      cardHeaderTitle: this.$store.getters.user.shop_id === WAREHOUSUE ? '出荷予定入力' : '返品予定入力'
    }
  },
  created() {
    this.getUsers();
    this.getShops();
  },
  methods: {
    goBack() {
      this.usersOpened ? this.$router.go(-1) : this.$router.push({path: '/users'})
    },
    selectShopChange (val) {
      this.cardboard.shop_id =  val.target.value  ? parseInt(val.target.value) : ''
    },
    selectUserChange(val) {
      this.cardboard.pick_user_id =  val.target.value  ? parseInt(val.target.value) : ''
    },
    getShops () {
      this.loading = true
      apiShop.all({screen_key : SCREEN_KEY.LIST_USERS}).then(response => {
        let shops = [];
        response.data.data.map(s =>{
          if (this.shopIdLogin != s.id) {
            shops.push({label: s.name, value: parseInt(s.id)})
          }
        })

        this.shopOptions = shops;
        this.loading = false
      }).catch(error => {
        this.loading = false
      })
    },
    getUsers() {
      this.loading = true
      userApi.getUserShop().then(response => {
        let users = [];
        response.data.map(s =>{
          users.push({label:s.name, value:parseInt(s.id)})
        })

        this.userOptions = users
      }).catch(error => {
      }).finally( () => {
        this.loading = false
      })
    },

    confirmRenderItemMaster() {
      this.$confirm(
          {
            title: '確認',
            message: this.shopIdLogin === this.wareHouse ? '出荷処理を行いますか？' : '返品処理を行いますか？',
            button: {
              no: 'いいえ',
              yes: 'はい'
            },
            callback: confirm => {
              if (confirm) {
                this.renderItemMaster()
              }
            }
          }
      )
    },

    renderItemMaster() {
      if (!this.cardboard.shop_id) {
        this.errorCardboard.shop_id = '出荷先を選択してください'
        return ;
      }

      if (!this.cardboard.pick_user_id) {
        this.errorCardboard.pick_user_id = '出荷担当者を選択してください'
        return ;
      }

      this.showItemForm = true;
      this.showCardboardForm = false;
    },

    confirmRegisterCardboard(val) {
      this.$confirm(
          {
            title: '確認',
            message: '段ボールに商品を登録しますか？',
            button: {
              no: 'いいえ',
              yes: 'はい'
            },
            callback: confirm => {
              if (confirm) {
                this.registerCardboard(val)
              }
            }
          }
      )
    },
    addItem(val) {
      this.confirmRegisterCardboard(val)
    },
    registerCardboard(val) {
      let items = val.items
      this.loading = true
      if(!items.length){
        Vue.$toast.error('少なくとも1つの製品が必要です')
        return;
      }

      let params = {
        cardboard: this.cardboard,
        items: items
      }
      apiCardboard.create(params).then(response => {
        Vue.$toast.success('登録成功しました。')
        setTimeout(this.$router.back(), 2000);
      }).catch(error => {
        Vue.$toast.error('少なくとも1つの製品が必要です')
      }).finally(() =>{
        this.loading = false
      })
    },
    goBackCardboard() {
      this.showCardboardForm = true
      this.showItemForm = false
    }
  }
}
</script>
